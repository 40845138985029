/**
 * Site-wide scripts for Textbase
 * 
 * Does things like:
 *
 * - checks for new messages and sets notifications and badges when necessary
 *
 * Textbase https://textbase.io
 * @author Jason Rosewell <https://linkhousemedia.com>
 */
// main visibility API function 
// use visibility API to check if current tab is active or not
var vis = (function(){
    var stateKey, 
        eventKey, 
        keys = {
                hidden: "visibilitychange",
                webkitHidden: "webkitvisibilitychange",
                mozHidden: "mozvisibilitychange",
                msHidden: "msvisibilitychange"
    };
    for (stateKey in keys) {
        if (stateKey in document) {
            eventKey = keys[stateKey];
            break;
        }
    }
    return function(c) {
        if (c) document.addEventListener(eventKey, c);
        return !document[stateKey];
    }
})();    

$(document).ready(function() {
    
    //Feature detection for FireFox
    var isFirefox = typeof InstallTrigger !== 'undefined';
    
    // Don't have a better place to put this right now (for radio toggle on account settings page)
    $(".radio-toggle").toggleInput();
    
    // START NOTIFICATION STUFF
    var permission = Push.Permission.get();
    
    // Check to see if we have permission yet. Also make sure user is logged in.
    if ( page.user_id && permission == 'default' ) {
        
        // We don't have permission. Ask for it.
        Push.Permission.request();
        
        /* This was to use a modal to ask permission. Not doing that but maybe later if required.
        $('body').ufModal({
            sourceUrl: site.uri.public + '/modals/notifications/request-permission',
            ajaxParams: {
                permission: permission
            },
            msgTarget: $('#alerts-page')
        });
        
        $('body').on('renderSuccess.ufModal', function (data) {
            var modal = $(this).ufModal('getModal');
            
            var onGranted = function(){
                modal.modal('hide');
            };
            
            var onDenied = function(){
                modal.modal('hide');
            };

            $('#notifications-set').on('click',function(){
                Push.Permission.request(onGranted, onDenied);
            });
        
        });*/
        
    }
        
    var audio = new Audio(page.audio_new);
              
    var unreadCount = function() {
        
        //Check notification table for push notifications and sounds
        $.getJSON( site.uri.public + "/api/notifications/browser/u/" + page.user_id, function( data ) {
                        
            // One message, so push a detailed notification with sound and clear the notification in the database
            if ( data.count == 1 ) {
                
                //Whether or not the thread is selected, we're going to clear the notification from the db
                var c = {};
                c[site.csrf.keys.name] = site.csrf.name;
                c[site.csrf.keys.value] = site.csrf.value;
                c["notification_id"] = data.notification.id;

                $.ajax({
                    url: site.uri.public + "/api/notifications/browser/clear",
                    method: 'PUT',
                    data: c,
                    dataType: 'json',
                    complete: function (d) {
                        //console.log(d);
                    }
                });
                
                //If this thread isn't already selected, OR the window isn't visible, push a notification 
                if ( ( data.message.outside_number_id != $("body").data('selected_thread') ) || !vis() ) {
                
                    Push.create("New SMS Message", {
                        body: data.message.message_body,
                        icon: page.icon,
                        timeout: 8000,
                        silent: true,
                        onClick: function () {
                            window.focus();
                            this.close();
                        }
                    });
                    
                    if ( page.sound == 1 && !isFirefox ) audio.play();
                
                }                
                
            } else if ( data.count > 1 ) {
                
                //Whether or not the thread is selected, we're going to clear the notifications from the db
                var c = {};
                c[site.csrf.keys.name] = site.csrf.name;
                c[site.csrf.keys.value] = site.csrf.value;

                $.ajax({
                    url: site.uri.public + "/api/notifications/browser/clear-all/u/" + page.user_id,
                    method: 'PUT',
                    data: c,
                    dataType: 'json',
                    complete: function (d) {
                        //console.log(d);
                    }
                });                
                
                //We won't check the open thread since this could be a group of messages from multiple threads. 
                Push.create("New SMS Messages", {
                    body: "You have more than one new messages waiting.",
                    icon: page.icon,
                    timeout: 8000,
                    silent: true,
                    onClick: function () {
                        window.focus();
                        this.close();
                    }
                });
                
                if ( page.sound == 1  && !isFirefox ) audio.play();
                
            }
            
        });
        
        //Check unread messages for badges
        $.getJSON( site.uri.public + "/api/messages/unread-count/u/" + page.user_id, function( data ) {
            
            // Count is zero and it's still visible; hide it, set the label to 0, and bail
            if ( data.count == 0 ) {
                if ( $('.nav-unread-count').is(':visible') ) {
                    $('.nav-unread-count')
                        .fadeOut('slow')
                        .find('.label')
                            .html(data.count)
                            .attr('title',data.count + ' Unread messages');
                }

                //update the browser window title but only if we're on the messages page.
                if ( page.hasOwnProperty("current") ) {
                    if ( page.current == 'messages' ) {
                        document.title = page.site_title + ' | Messages';
                    }
                }
                
                return false;
            }
            
            // Count hasn't changed and it's visible already; do nothing
            if ( data.count == parseInt( $('.nav-unread-count .label').html()) && $('.nav-unread-count').is(':visible') ) {
                return false;
            }
            
            // Count is greater than zero and it's not visible; show it, update the count if necessary, and bail             
            if ( data.count > 0 && $('.nav-unread-count').is(':hidden') ) {

                $('.nav-unread-count').fadeIn('slow');
                
                if ( data.count != parseInt($('.nav-unread-count .label').html()) ) {
                    $('.nav-unread-count .label').html(data.count).attr('title',data.count + ' Unread messages');
                }
                
                //update the browser window title
                var pluralization = data.count == 1 ? ' New Message | ' : ' New Messages | ';
                document.title = data.count + pluralization + page.site_title;
                
                //audio.play();
                
                return false;
                
            }
            
            // Count is greater than zero, is visible, and has changed; update it
            if ( data.count > 0 && data.count != parseInt($('.nav-unread-count .label').html()) && $('.nav-unread-count').is(':visible') ) {

                $('.nav-unread-count .label')
                    .html(data.count)
                    .attr('title',data.count + ' Unread messages');;
                
                //update the browser window title
                var pluralization = data.count == 1 ? ' New Message | ' : ' New Messages | ';
                document.title = data.count + pluralization + page.site_title;
                
                return false;
                
            }
            
            // Otherwise, just show it
            $('.nav-unread-count').fadeIn('slow'); 
            
            
        });
    
    }
    
    if ( page.user_id ) {
        unreadCount();
        unreadIntervalTimer = setInterval(unreadCount, 4000);
    }
    

/*
/  This is for using the push API directly which we're not ready to do yet.
/===========================================================================  
    var testPush = function(){
    
        if (!('serviceWorker' in navigator)) {
          // Service Worker isn't supported on this browser, disable or hide UI.
          return;
        }
        
        if (!('PushManager' in window)) {
          // Push isn't supported on this browser, disable or hide UI.
          return;
        }
        
        var registration = registerServiceWorker();
        
        if ( registration ) {
            
            askPermission();
        
        }
    
    }
    
    //testPush();  

    //Register Service Worker
    function registerServiceWorker() {
      return navigator.serviceWorker.register(page.service_worker)
      .then(function(registration) {
        //console.log('Service worker successfully registered.');
        return registration;
      })
      .catch(function(err) {
        console.error('Unable to register service worker.', err);
      });
    }
    
    //Request user permission
    function askPermission() {
      return new Promise(function(resolve, reject) {
        const permissionResult = Notification.requestPermission(function(result) {
          resolve(result);
        });
    
        if (permissionResult) {
          permissionResult.then(resolve, reject);
        }
      })
      .then(function(permissionResult) {
        if (permissionResult !== 'granted') {
          throw new Error('We weren\'t granted permission.');
        }
      });
    }
    
    function urlBase64ToUint8Array(base64String) {
      const padding = '='.repeat((4 - base64String.length % 4) % 4);
      const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/')
      ;
      const rawData = window.atob(base64);
      return Uint8Array.from([...rawData].map((char) => char.charCodeAt(0)));
    }    
    
    function subscribeUserToPush() {
      return navigator.serviceWorker.register(page.service_worker)
      .then(function(registration) {
        const subscribeOptions = {
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(
            'BA-a-u3xf-kHqlnSpF9bXEz0zipvdmrQORxg3ZRYQSKyBXlTP9986kpPUV38avVB28GqRGc_JFeeiuWhqnwE0fo'
          )
        };
    
        return registration.pushManager.subscribe(subscribeOptions);
      })
      .then(function(pushSubscription) {
        sendSubscriptionToBackEnd( JSON.stringify(pushSubscription) );
        return pushSubscription;
      });
    }    
    
    //subscribeUserToPush();
    
    //Send subscription object to back end
    
    
    function sendSubscriptionToBackEnd(subscription) {
        
      return fetch(site.uri.public + '/api/push/save-subscription', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: subscription
      })
      .then(function(response) {
        if (!response.ok) {
          throw new Error('Bad status code from server.');
        }
    
        return response.json();
      })
      .then(function(responseData) {
        if (!(responseData.data && responseData.data.success)) {
          throw new Error('Bad response from server.');
        }
      });
    }
    
    //sendSubscriptionToBackEnd( subscribeUserToPush() );
    
    
    //This one checks for new messages inside the threads - okay for badges since we want notifications like this to persist
	var notifications = function(){
		
		$.getJSON( site.uri.public + "/api/threads/list/u/" + page.user_id, function( data ) {
			
			var items = [];	
			var n = 0;
			$("body").data('all_read',true);
			
			$.each( data, function( key, val ) {
    			
				var is_selected = val.outside_number_id == $("body").data('selected_thread') ? true : false;
				var selected    = is_selected === true ? ' bg-aqua-active' : '';
				var unread      = is_selected === false && val.is_read == 0 ? ' unread' : '';
				
				if ( val.is_read == 0 ) {
    				$("body").data('all_read',false);
    				n++;
                }
				
			});
			
			//Set the document title when there are unread messages that don't have focus
			if ( $("body").data('all_read') === false ) {
			    document.title = 'New Messages | ' + page.site_title;
            } else {
                document.title = page.site_title + ' | Messages';
            }
            
            if ( n > 0 ) {
                $("#messages-unread-count").html( n );
            } else {
                $("#messages-unread-count").html("");
            }
		
		});
		
		return 1;
		
	}
	//notifications();
	//setInterval(notifications, 3000);
*/
	
});
